import {
	createContext,
	useContext,
	ReactNode,
	ReactElement,
	useMemo,
	useState,
	useEffect,
	useRef
} from 'react';
import assert from 'assert';
import { Task } from '@techshift/callie-arc-api-typescript';
import { useOpenApi } from '../../core/providers';
import { usePrevious, useWebsocket } from '@techshift/react-core';

export interface TaskContextData {
	task: Task;
	isAlerted: boolean;
}

export const TaskContext = createContext<TaskContextData | undefined>(
	undefined
);

type TaskProvderProps = {
	task: Task;
	readonly children?: ReactNode;
};
export function TaskProvider(props: TaskProvderProps): ReactElement {
	const { task: _task, children } = props;

	const [loading, setLoading] = useState(false);
	const [task, setTask] = useState<Task>(_task);

	const isAlerted: boolean = useMemo(
		() => (task.alertType && task.status === 'open' ? true : false),
		[task.alertType, task.status]
	);

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);
	useEffect(() => {
		openApiRef.current = openApi;
	});

	const { subscribe, unsubscribe, on, off, authenticated } = useWebsocket();
	const prevAuthenticated = usePrevious(authenticated);

	/**
	 * Fetch data
	 */
	useEffect(() => {
		if (loading) {
			return;
		}

		let shouldFetch = false;

		// Always fetch if the socket has turned off and back on again (As we have a period of time where we don't get updates)
		if (authenticated && !prevAuthenticated) {
			shouldFetch = true;
		}

		if (!shouldFetch) {
			return;
		}

		function fetch() {
			return openApiRef.current.tasks
				.getTask(task.id)
				.then((response) => {
					setTask(response.data.data.task);
				})
				.catch(() => {
					// Wait 5 seconds before retrying
					return new Promise((resolve) => {
						setTimeout(() => {
							resolve(fetch());
						}, 5000);
					});
				});
		}

		setLoading(true);
		fetch().finally(() => {
			setLoading(false);
		});
	}, [authenticated, loading, prevAuthenticated, task.id]);

	/**
	 * Websockets
	 */
	useEffect(() => {
		const ids = subscribe([{ resource: `arc_tasks#${task.id}` }]);
		return () => {
			unsubscribe(ids);
		};
	}, [task.id, subscribe, unsubscribe]);

	useEffect(() => {
		const tasksCallback = (message: any) => {
			if (message.data.task.id === task.id) {
				setTask(message.data.task);
			}
		};
		on('tasks', tasksCallback);

		return () => {
			off('tasks', tasksCallback);
		};
	}, [on, off, setTask, task.id]);

	return (
		<TaskContext.Provider
			value={{
				task,
				isAlerted
			}}
		>
			{children}
		</TaskContext.Provider>
	);
}

export function useTask(): TaskContextData {
	const data = useContext<TaskContextData | undefined>(TaskContext);

	assert(
		data,
		"Task unavailable. Are you sure you're using this in a nested component of an TaskProvider?"
	);

	return data;
}
