import { Box, Button, Modal, ModalClose, Sheet, Typography } from '@mui/joy';
import { Task } from '@techshift/callie-arc-api-typescript';
import { parseApiError } from '@techshift/react-core';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useOpenApi } from '../../core/providers';
import toast from 'react-hot-toast';

type TaskHiddenProps = {
	task: Task;
};
export function TaskHidden(props: TaskHiddenProps): ReactElement {
	const { task } = props;

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);
	useEffect(() => {
		openApiRef.current = openApi;
	});

	function hide() {
		setLoading(true);
		openApiRef.current.tasks
			.hide(task.id)
			.then(() => {
				toast.success('Task hidden');
				setOpen(false);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'TASK_NOT_FOUND') {
							setError('Task not found');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function unhide() {
		setLoading(true);
		openApiRef.current.tasks
			.unhide(task.id)
			.then(() => {
				toast.success('Task unhidden');
				setOpen(false);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'TASK_NOT_FOUND') {
							setError('Task not found');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}

	useEffect(() => {
		if (error) {
			toast.error(error);
		}
	}, [error]);

	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
				<Box>
					{task.arcHidden && (
						<Button
							loading={loading}
							color="success"
							variant="solid"
							onClick={() => setOpen(true)}
						>
							Unhide task
						</Button>
					)}
					{!task.arcHidden && (
						<Button
							loading={loading}
							color="danger"
							variant="outlined"
							onClick={() => setOpen(true)}
						>
							Hide task
						</Button>
					)}
				</Box>
			</Box>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-desc"
				open={open}
				onClose={() => setOpen(false)}
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			>
				<Sheet
					variant="outlined"
					sx={{
						maxWidth: 500,
						borderRadius: 'md',
						p: 3,
						boxShadow: 'lg'
					}}
				>
					<ModalClose
						variant="outlined"
						sx={{
							top: 'calc(-1/4 * var(--IconButton-size))',
							right: 'calc(-1/4 * var(--IconButton-size))',
							boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
							borderRadius: '50%',
							bgcolor: 'background.body'
						}}
					/>
					<Typography
						component="h2"
						id="modal-title"
						level="h4"
						textColor="inherit"
						fontWeight="lg"
						mb={1}
					>
						{task.arcHidden ? 'Unhide task' : 'Hide task'}
					</Typography>
					<Typography id="modal-desc" textColor="text.tertiary">
						Are you sure you want to {task.arcHidden ? 'unhide' : 'hide'} this
						task? It will take affect for all ARC operators
					</Typography>
					<Button
						loading={loading}
						color={task.arcHidden ? 'success' : 'danger'}
						variant="solid"
						sx={{ marginTop: 2 }}
						onClick={() => {
							if (task.arcHidden) {
								unhide();
							} else {
								hide();
							}
						}}
					>
						{task.arcHidden ? 'Unhide' : 'Hide'}
					</Button>
				</Sheet>
			</Modal>
		</>
	);
}
