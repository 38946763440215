import { ReactElement, useEffect, ReactNode } from 'react';
import { Task, TaskLog } from '@techshift/callie-arc-api-typescript';
import { useWebsocket } from '@techshift/react-core';
import alarmSfx from '../../assets/audio/alarm.wav';
import useSound from 'use-sound';
import { datadogLogs } from '@datadog/browser-logs';

export interface TaskAlarmContextData {
	task: Task;
	isAlerted: boolean;
}

type TaskAlarmProviderProps = {
	children: ReactNode;
};
export function TaskAlarmProvider(props: TaskAlarmProviderProps): ReactElement {
	const { children } = props;

	const [play] = useSound(alarmSfx);

	const { subscribe, unsubscribe, on, off } = useWebsocket();

	/**
	 * Websockets
	 */
	useEffect(() => {
		const ids = subscribe([{ resource: 'arc_tasks#all.logs' }]);
		return () => {
			unsubscribe(ids);
		};
	}, [subscribe, unsubscribe]);

	useEffect(() => {
		const callback = (message: any) => {
			const taskLogs = message.data.taskLogs as TaskLog[];

			const alarmLogTypes = [
				'opened_instant_alert',
				'alerted_timeout',
				'alerted_manual_app',
				'alerted_manual_button'
			];

			let alertedLogReceived = false;
			for (const taskLog of taskLogs) {
				if (alarmLogTypes.includes(taskLog.taskLogType)) {
					alertedLogReceived = true;
				}
			}

			if (alertedLogReceived) {
				datadogLogs.logger.info(
					'TASK_ANALYTICS: Alerted task log received. Playing sound',
					{
						taskLogs
					}
				);
				play();
			}
		};
		on('tasks_logs', callback);

		return () => {
			off('tasks_logs', callback);
		};
	}, [on, off, play]);

	return <>{children}</>;
}
