import { ReactElement } from 'react';
import { TasksTable } from '../../tasks/components';
import Box from '@mui/joy/Box';
import { useNavigate } from 'react-router-dom';
import { TasksProvider, useTasks } from '../../tasks/providers/tasks.provider';

export function TasksAllPage(): ReactElement {
	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				alignItems: 'center'
			}}
		>
			<Box
				sx={{
					maxWidth: {
						xs: '100%',
						sm: '1000px'
					}
				}}
			>
				<TasksProvider>
					<TasksAllPage2 />
				</TasksProvider>
			</Box>
		</Box>
	);
}

function TasksAllPage2(): ReactElement {
	const navigate = useNavigate();
	const { tasks, fetchNext, hasMore } = useTasks();

	return (
		<TasksTable
			onTaskClick={(task) => navigate(`/tasks/${task.id}`)}
			tasks={tasks}
			fetchNext={fetchNext}
			hasMore={hasMore}
		/>
	);
}
