import Table from '@mui/joy/Table';
import { ReactElement } from 'react';
import { Task } from '@techshift/callie-arc-api-typescript';
import Sheet from '@mui/joy/Sheet';
import Avatar from '@mui/joy/Avatar';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { TaskStatusIndicator } from './task-status-indicator';
import { TaskTimeIndicator } from './task-time-indicator';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from '@mui/joy';

type TasksTableProps = {
	onTaskClick: (task: Task) => void;
	tasks: Task[];
	fetchNext?: () => void;
	hasMore?: boolean;
};

export function TasksTable(props: TasksTableProps): ReactElement {
	const {
		onTaskClick,
		tasks,
		fetchNext = () => {
			return;
		},
		hasMore = false
	} = props;

	return (
		<InfiniteScroll
			dataLength={tasks.length} //This is important field to render the next data
			next={fetchNext}
			hasMore={hasMore}
			loader={<Typography textAlign={'center'}>Loading</Typography>}
			endMessage={<Typography textAlign={'center'}>No more tasks</Typography>}
		>
			<TasksTableView tasks={tasks} onTaskClick={onTaskClick} />
		</InfiniteScroll>
	);
}

type TasksTableViewProps = {
	tasks: Task[];
	onTaskClick: (task: Task) => void;
};
function TasksTableView(props: TasksTableViewProps): ReactElement {
	const { tasks, onTaskClick } = props;

	const theme = useTheme();
	return (
		<Sheet sx={{ borderRadius: '16px', overflow: 'hidden' }}>
			<Table
				hoverRow
				borderAxis="none"
				sx={{
					'& thead th': { backgroundColor: '' },
					'& thead th:nth-child(3)': { width: '20%' },
					'& thead th:nth-child(4)': {
						xs: { width: '60px' },
						sm: { width: '80px' }
					},
					'& thead th:nth-child(6)': { width: '20px' },
					'& tr > *:nth-child(1)': { paddingLeft: '24px' },
					'& tr > *:nth-child(6)': { paddingRight: '24px' }
				}}
			>
				<thead>
					<tr style={{ padding: '16px' }}>
						<th>User</th>
						<th>Activity</th>
						<th>Time</th>
						<th>Status</th>
						<th>Assignees</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{tasks.map((task) => {
						const isAlerted = task.status === 'open' && task.alertType !== null;
						const isAssigned = task.arcAssigned.length > 0;
						return (
							<tr
								key={task.id}
								onClick={() => {
									onTaskClick(task);
								}}
								style={{
									backgroundColor:
										isAlerted && !isAssigned
											? theme.palette.danger[100]
											: undefined,
									cursor: 'pointer',
									height: '75px'
								}}
							>
								<td>
									{task.user ? (
										<Box
											sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}
										>
											<Avatar
												size="sm"
												alt={`${task.user.firstName} ${task.user.lastName}`}
												src={task.user.image}
											/>
											<Box sx={{ minWidth: 0 }}>
												<Typography noWrap fontWeight="lg">
													{`${task.user.firstName} ${task.user.lastName}`}
												</Typography>
											</Box>
										</Box>
									) : (
										'Unknown'
									)}
								</td>
								<td>
									{task.taskType === 'timed' ? task.name : 'Instant alert'}
								</td>
								<td>
									<TaskTimeIndicator task={task} />
								</td>
								<td>
									<TaskStatusIndicator task={task} />
								</td>
								<td>
									<Box sx={{}}>
										{task.arcAssigned.map((arc) => (
											<Typography key={arc.operatorId}>{arc.name}</Typography>
										))}

										{task.arcAssigned.length === 0 && (
											<Typography>No one assigned</Typography>
										)}
									</Box>
								</td>
								<td>
									<ChevronRightIcon />
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</Sheet>
	);
}
