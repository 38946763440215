import { Box, List } from '@mui/joy';
import { Task, TaskLog } from '@techshift/callie-arc-api-typescript';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { TaskLogItem } from './task-log-item';

type TaskLogListProps = {
	taskLogs: TaskLog[];
	task: Task;
};
export function TaskLogList(props: TaskLogListProps): ReactElement {
	const { taskLogs, task } = props;

	const ref = useRef<any>(null);
	const [height, setHeight] = useState(0);
	useEffect(() => {
		setHeight(ref.current?.clientHeight ?? 0);
	}, [height]);

	return (
		<Box sx={{ display: 'flex', flex: 1 }}>
			<Box
				sx={{
					position: 'relative',
					display: 'flex',
					flex: 1
				}}
			>
				<Box
					ref={ref}
					sx={{
						position: 'absolute',
						left: 0,
						top: 0,
						right: 0,
						bottom: 0
					}}
				>
					<List
						variant="plain"
						sx={{
							display: 'flex',
							flex: 1,
							overflowY: 'auto',
							height: `${height}px`
						}}
					>
						{taskLogs.map((taskLog) => (
							<TaskLogItem
								key={taskLog.id}
								taskLog={taskLog}
								user={task.user}
							/>
						))}
					</List>
				</Box>
			</Box>
		</Box>
	);
}
