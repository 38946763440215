import { useState } from 'react';
import styles from './login.module.scss';
import { useLoginService } from '../../services';
import { LoginForm, LoginFormData } from '../login-form';
import { parseApiError } from '@techshift/react-core';
import Alert from '@mui/joy/Alert';

type LoginProps = {
	onSuccess?: () => void;
};
export function Login(props: LoginProps) {
	const { onSuccess } = props;

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const { login } = useLoginService();

	const onSubmit = ({ email, password }: LoginFormData) => {
		setLoading(true);
		login({ email, password })
			.then(() => {
				onSuccess?.();
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'NO_ACCOUNT') {
							setError('No account with this email/password');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className={styles.container}>
			{error && (
				<>
					<Alert color="danger">{error}</Alert>
					<br />
				</>
			)}
			<LoginForm onSubmit={onSubmit} loading={loading} />
		</div>
	);
}
