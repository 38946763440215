import { Box, Button, List, ListItem, Typography } from '@mui/joy';
import {
	ArcOperatorAssigned,
	Task
} from '@techshift/callie-arc-api-typescript';
import { parseApiError, useTokens } from '@techshift/react-core';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useOpenApi } from '../../core/providers';
import toast from 'react-hot-toast';
import { getLogTime } from '../../core/util/time';

type TaskAssignedProps = {
	task: Task;
};
export function TaskAssigned(props: TaskAssignedProps): ReactElement {
	const { task } = props;

	const { tokens } = useTokens();
	const operatorId = tokens.refreshTokenPayload.sub;

	const isThisUserAssigned = useMemo(() => {
		return task.arcAssigned.some(
			(operator) => operator.operatorId === operatorId
		);
	}, [task.arcAssigned, operatorId]);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);
	useEffect(() => {
		openApiRef.current = openApi;
	});

	function assign() {
		setLoading(true);
		openApiRef.current.tasks
			.assign(task.id)
			.then(() => {
				toast.success('Assigned to task');
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'TASK_NOT_FOUND') {
							setError('Task not found');
						} else if (
							response.data.message === 'ARC_OPERATOR_ALREADY_ASSIGNED'
						) {
							setError('Already assigned to task');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function unassign() {
		setLoading(true);
		openApiRef.current.tasks
			.unassign(task.id)
			.then(() => {
				toast.success('Unassigned from task');
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'TASK_NOT_FOUND') {
							setError('Task not found');
						} else if (response.data.message === 'ARC_OPERATOR_NOT_ASSIGNED') {
							setError('Cannot unassign as you were not already assigned');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}

	useEffect(() => {
		if (error) {
			toast.error(error);
		}
	}, [error]);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
			<List variant="plain">
				{task.arcAssigned.map((operator) => {
					return (
						<TaskAssignedItem key={operator.operatorId} operator={operator} />
					);
				})}
				{task.arcAssigned.length === 0 && (
					<Typography>No one assigned</Typography>
				)}
			</List>
			<Box>
				{isThisUserAssigned && (
					<Button
						loading={loading}
						color="danger"
						variant="outlined"
						onClick={unassign}
					>
						Unassign yourself
					</Button>
				)}
				{!isThisUserAssigned && (
					<Button
						loading={loading}
						color="success"
						variant="solid"
						onClick={assign}
					>
						Assign yourself
					</Button>
				)}
			</Box>
		</Box>
	);
}

type TaskAssignedItemProps = {
	operator: ArcOperatorAssigned;
};
function TaskAssignedItem(props: TaskAssignedItemProps): ReactElement {
	const { operator } = props;

	const time = useMemo(() => {
		return getLogTime(new Date(operator.timestamp));
	}, [operator.timestamp]);

	return (
		<ListItem
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'flex-start',
				alignItems: 'center',
				gap: '8px'
			}}
		>
			<Typography>{`${operator.name}`}</Typography>
			<Typography fontSize="xs" color="neutral">
				{`(${time})`}
			</Typography>
		</ListItem>
	);
}
