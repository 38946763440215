import { ReactElement } from 'react';
import styles from './app-loading-error.module.scss';

type AppLoadingErrorProps = {
	onRetryClick: () => void;
};
export function AppLoadingError(props: AppLoadingErrorProps): ReactElement {
	const { onRetryClick } = props;
	return (
		<div className={styles.container}>
			<h2>Something wrong happened</h2>
			<button onClick={onRetryClick}>Retry</button>
		</div>
	);
}
