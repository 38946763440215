import {
	createContext,
	useContext,
	ReactNode,
	ReactElement,
	useRef,
	useEffect,
	useState,
	useCallback,
	useMemo
} from 'react';
import assert from 'assert';
import { Task } from '@techshift/callie-arc-api-typescript';
import { useOpenApi } from '../../core/providers';
import { useWebsocket } from '@techshift/react-core';

const LIMIT = 25;

export interface TasksContextData {
	tasks: Task[];
	fetchNext: () => void;
	hasMore: boolean;
}

export const TasksContext = createContext<TasksContextData | undefined>(
	undefined
);

type TasksProvderProps = {
	readonly status?: 'open' | 'closed';
	readonly isAlerted?: boolean;
	readonly children?: ReactNode;
};
export function TasksProvider(props: TasksProvderProps): ReactElement {
	const { status, isAlerted, children } = props;

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);
	useEffect(() => {
		openApiRef.current = openApi;
	});

	const { subscribe, unsubscribe, on, off } = useWebsocket();

	useEffect(() => {
		const ids = subscribe([{ resource: `arc_tasks#all` }]);
		return () => {
			unsubscribe(ids);
		};
	}, [subscribe, unsubscribe]);

	const [taskMap, setTasksMap] = useState<Record<string, Task>>({});
	const [nextOffset, setNextOffset] = useState(0);
	const [hasMore, setHasMore] = useState(true);

	useEffect(() => {
		const tasksCallback = (message: any) => {
			const task: Task = message.data.task;
			setTasksMap((current) => ({
				...current,
				[task.id]: task
			}));
		};
		on('tasks', tasksCallback);

		return () => {
			off('tasks', tasksCallback);
		};
	}, [on, off]);

	const fetchData = useCallback(
		(offset: number) => {
			openApiRef.current.tasks
				.getTasks({ limit: LIMIT, offset, status, isAlerted })
				.then((response) => {
					setTasksMap((currentTasksMap) => {
						const newMap = response.data.data.tasks.data.reduce((acc, task) => {
							acc[task.id] = task;
							return acc;
						}, {} as Record<string, Task>);
						return { ...currentTasksMap, ...newMap };
					});
					response.data.data.tasks.offset = offset;
					setHasMore(response.data.data.tasks.hasMore);
					setNextOffset(response.data.data.tasks.offset + LIMIT);
				});
		},
		[status, isAlerted]
	);

	useEffect(() => {
		fetchData(0);
	}, [fetchData]);

	const tasks = useMemo(() => {
		const _tasks = Object.values(taskMap)
			.filter((task) => {
				let hasStatusMatch = true;
				if (status !== undefined) {
					hasStatusMatch = task.status === status;
				}

				let hasIsAlertedMatch = true;
				if (isAlerted !== undefined) {
					if (isAlerted) {
						hasIsAlertedMatch = task.alertedAt !== null;
					} else {
						hasIsAlertedMatch = task.alertedAt === null;
					}
				}

				return hasStatusMatch && hasIsAlertedMatch;
			})
			.sort((a, b) => {
				if (a.primarySortIndex !== b.primarySortIndex) {
					return a.primarySortIndex - b.primarySortIndex;
				} else {
					return (
						new Date(b.secondarySortIndex).getTime() -
						new Date(a.secondarySortIndex).getTime()
					);
				}
			});

		return _tasks;
	}, [taskMap, status, isAlerted]);

	return (
		<TasksContext.Provider
			value={{
				tasks,
				fetchNext: () => {
					fetchData(nextOffset);
				},
				hasMore
			}}
		>
			{children}
		</TasksContext.Provider>
	);
}

export function useTasks(): TasksContextData {
	const data = useContext<TasksContextData | undefined>(TasksContext);

	assert(
		data,
		"Tasks unavailable. Are you sure you're using this in a nested component of an TasksProvider?"
	);

	return data;
}
