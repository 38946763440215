import { ReactElement, useState, useEffect, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {
	ApiProvider,
	useAuthenticatedApi,
	useNullableTokens,
	TokensProvider,
	useTokens,
	PreferencesProvider,
	WebsocketProvider,
	useEnv
} from '@techshift/react-core';
import { AppLoading } from '../app-loading';
import { AppLoadingError } from '../app-loading-error';
import { OpenApiProvider, useOpenApi } from '../../providers';
import {
	AlertedTasksProvider,
	TaskAlarmProvider
} from '../../../tasks/providers';

type AppAuthInitProps = {
	children?: ReactNode;
};
export function AppAuthInit(props: AppAuthInitProps): ReactElement {
	const { children } = props;

	const { tokens } = useNullableTokens();
	const location = useLocation();

	if (!tokens) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return (
		<TokensProvider tokens={tokens}>
			<PreferencesProvider>
				<OpenApiProvider>
					<AppAuthInit2>{children}</AppAuthInit2>
				</OpenApiProvider>
			</PreferencesProvider>
		</TokensProvider>
	);
}

type AppAuthInit2Props = {
	children?: ReactNode;
};
function AppAuthInit2(props: AppAuthInit2Props): ReactElement {
	const { children } = props;

	const { tokens } = useTokens();

	const { openApi } = useOpenApi();

	const { api } = useAuthenticatedApi({
		axiosInstance: openApi.instance,
		accessToken: tokens.accessToken,
		refreshToken: tokens.refreshToken,
		refreshUrl: 'v1/auth/refresh'
	});

	const { baseUrl } = useEnv();

	return (
		<ApiProvider api={api}>
			<WebsocketProvider
				accessToken={tokens.accessToken}
				refreshToken={tokens.refreshToken}
				refreshUrl={'v1/auth/refresh'}
				socketUrl={baseUrl + '/private'}
				socketPath={'/v1/sockets/socket.io'}
			>
				<AppAuthInit3>{children}</AppAuthInit3>
			</WebsocketProvider>
		</ApiProvider>
	);
}

type AppAuthInit3Props = {
	children?: ReactNode;
};
function AppAuthInit3(props: AppAuthInit3Props): ReactElement {
	const { children } = props;

	// const { fetchCompanies } = useCompaniesService();
	// const { fetchRoles } = useRolesService();

	const [state, setState] = useState<{ loading: boolean; error: string }>({
		loading: true,
		error: ''
	});

	// Fake loading to make sure the loading screen doesn't "flicker" too quickly.
	// So this loading screen will last for at least 1 second.
	const [fakeLoading, setFakeLoading] = useState(true);
	useEffect(() => {
		if (fakeLoading) {
			setTimeout(() => {
				setFakeLoading(false);
			}, 1000);
		}
	}, [fakeLoading]);

	const fetch = () => {
		setFakeLoading(true);
		setState({ loading: false, error: '' });

		// setFakeLoading(true);
		// setState({ loading: true, error: '' });
		// Promise.all([fetchCompanies(), fetchRoles()])
		// 	.then(() => setState({ loading: false, error: '' }))
		// 	.catch((error) => {
		// 		setState({ loading: false, error: error.message });
		// 	});
	};
	useEffect(() => {
		fetch();
	}, []);

	if (fakeLoading || state.loading) {
		return <AppLoading />;
	}

	if (state.error) {
		return <AppLoadingError onRetryClick={fetch} />;
	}

	return (
		<AlertedTasksProvider>
			<TaskAlarmProvider>{children}</TaskAlarmProvider>
		</AlertedTasksProvider>
	);
}
