import { ReactElement } from 'react';
import { TasksTable } from '../../tasks/components';
import Box from '@mui/joy/Box';
import { useNavigate } from 'react-router-dom';
import { useAlertedTasks } from '../../tasks/providers';

export function TasksAlertedPage(): ReactElement {
	const navigate = useNavigate();

	const { tasks } = useAlertedTasks();

	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				alignItems: 'center'
			}}
		>
			<Box
				sx={{
					maxWidth: {
						xs: '100%',
						sm: '1000px'
					}
				}}
			>
				<TasksTable
					onTaskClick={(task) => navigate(`/tasks/${task.id}`)}
					tasks={tasks}
				/>
			</Box>
		</Box>
	);
}
