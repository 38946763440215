import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import FormHelperText from '@mui/joy/FormHelperText';

export type LoginFormData = {
	email: string;
	password: string;
};

const schema = Joi.object({
	email: Joi.string()
		.email({ minDomainSegments: 2, tlds: { allow: ['com'] } })
		.required(),

	password: Joi.string().max(64).required()
});

export type LoginFormProps = {
	onSubmit?: (data: LoginFormData) => void;
	loading?: boolean;
};

export function LoginForm(props: LoginFormProps) {
	const {
		onSubmit = () => {
			return;
		},
		loading
	} = props;

	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm<LoginFormData>({
		resolver: joiResolver(schema)
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Typography level="h3">Callie ARC Login</Typography>
			<br />
			<div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
				<FormControl>
					<FormLabel>Email</FormLabel>
					<Input {...register('email')} error={!!errors.email?.message} />
					{errors.email?.message && (
						<FormHelperText>{errors.email?.message}</FormHelperText>
					)}
				</FormControl>

				<FormControl>
					<FormLabel>Password</FormLabel>
					<Input
						{...register('password')}
						type="password"
						error={!!errors.password?.message}
					/>
					{errors.password?.message && (
						<FormHelperText>{errors.password?.message}</FormHelperText>
					)}
				</FormControl>
			</div>
			<br />
			<Button type={'submit'} loading={loading}>
				Login
			</Button>
		</form>
	);
}
