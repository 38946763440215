import {
	createContext,
	useContext,
	ReactNode,
	ReactElement,
	useState,
	useEffect,
	useRef,
	useCallback
} from 'react';
import assert from 'assert';
import { TaskLog } from '@techshift/callie-arc-api-typescript';
import { useOpenApi } from '../../core/providers';
import { usePrevious, useWebsocket } from '@techshift/react-core';

const LIMIT = 50;

export interface TaskLogsContextData {
	taskLogs: TaskLog[];
	loading: boolean;
}

export const TaskLogsContext = createContext<TaskLogsContextData | undefined>(
	undefined
);

type TaskLogsProvderProps = {
	readonly taskId: string;
	readonly children?: ReactNode;
};
export function TaskLogsProvider(props: TaskLogsProvderProps): ReactElement {
	const { taskId, children } = props;

	const [loading, setLoading] = useState(false);
	const [taskLogs, _setTaskLogs] = useState<TaskLog[]>([]);
	const [hasInitialFetched, setHasInitialFetched] = useState(false);

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);
	useEffect(() => {
		openApiRef.current = openApi;
	});

	const { subscribe, unsubscribe, on, off, authenticated } = useWebsocket();
	const prevAuthenticated = usePrevious(authenticated);

	const sortAndAppendTaskLogs = useCallback((newTaskLogs: TaskLog[]) => {
		_setTaskLogs((prev) => {
			function uniqueTaskLogs(__taskLogs: TaskLog[]): TaskLog[] {
				const taskLogMap: { [id: string]: TaskLog } = {};
				__taskLogs.forEach((taskLog) => {
					taskLogMap[taskLog.id] = taskLog;
				});
				return Object.values(taskLogMap);
			}
			function sortTaskLogs(__taskLogs: TaskLog[]): TaskLog[] {
				return __taskLogs.sort((a, b) =>
					b.createdAt < a.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
				);
			}
			const result = sortTaskLogs(uniqueTaskLogs([...newTaskLogs, ...prev]));
			return result;
		});
	}, []);

	/**
	 *  Fetch task log data
	 */
	useEffect(() => {
		if (loading) {
			return;
		}

		let shouldFetch = false;

		// Always fetch if not done so already
		if (!hasInitialFetched) {
			shouldFetch = true;
		}

		// Always fetch if the socket has turned off and back on again (As we have a period of time where we don't get updates)
		if (authenticated && !prevAuthenticated) {
			shouldFetch = true;
		}

		if (!shouldFetch) {
			return;
		}

		async function fetch(offset: number): Promise<void> {
			return openApiRef.current.tasks
				.getTaskLogs(taskId, {
					sortOrder: 'desc',
					limit: LIMIT,
					offset: offset * LIMIT
				})
				.then((response) => {
					const data = response.data.data.taskLogs;
					if (data.data.length > 0) {
						sortAndAppendTaskLogs(data.data);
					}
					if (data.hasMore) {
						return fetch(offset + 1);
					}
				})
				.catch(() => {
					return new Promise((resolve) => {
						setTimeout(() => {
							resolve(fetch(offset));
						}, 5000);
					});
				});
		}

		setLoading(true);
		fetch(0).finally(() => {
			setHasInitialFetched(true);
			setLoading(false);
		});
	}, [
		taskId,
		authenticated,
		hasInitialFetched,
		loading,
		prevAuthenticated,
		sortAndAppendTaskLogs
	]);

	/**
	 * Task logs websockets
	 */
	useEffect(() => {
		const ids = subscribe([{ resource: `arc_tasks#${taskId}.logs` }]);
		return () => {
			unsubscribe(ids);
		};
	}, [taskId, subscribe, unsubscribe]);

	useEffect(() => {
		const callback = (message: any) => {
			const _taskLogs = message.data.taskLogs as TaskLog[];
			if (_taskLogs.length > 0 && _taskLogs[0].taskId === taskId) {
				sortAndAppendTaskLogs(_taskLogs);
			}
		};
		on('tasks_logs', callback);

		return () => {
			off('tasks_logs', callback);
		};
	}, [on, off, sortAndAppendTaskLogs, taskId]);

	return (
		<TaskLogsContext.Provider
			value={{
				taskLogs,
				loading
			}}
		>
			{children}
		</TaskLogsContext.Provider>
	);
}

export function useTaskLogs(): TaskLogsContextData {
	const data = useContext<TaskLogsContextData | undefined>(TaskLogsContext);

	assert(
		data,
		"TaskLogs unavailable. Are you sure you're using this in a nested component of an TaskLogsProvider?"
	);

	return data;
}
