import { Typography } from '@mui/joy';
import Box from '@mui/joy/Box';
import { Task } from '@techshift/callie-arc-api-typescript';
import { ReactElement, useMemo } from 'react';

type TaskStatusIndicatorProps = {
	task: Task;
};
export function TaskStatusIndicator(
	props: TaskStatusIndicatorProps
): ReactElement {
	const { task } = props;

	const [text, backgroundColor, textColor] = useMemo(() => {
		if (task.status === 'open') {
			if (task.alertType !== null) {
				return ['Alerted', 'danger.100', 'danger'];
			} else {
				return ['Active', 'success.100', 'success'];
			}
		} else {
			return ['Closed', 'neutral.100', 'neutral'];
		}
	}, [task.status, task.alertType]);

	return (
		<Box
			sx={{ padding: '4px', borderRadius: '999px', bgcolor: backgroundColor }}
		>
			<Typography color={textColor as any} fontWeight="lg" textAlign="center">
				{text}
			</Typography>
		</Box>
	);
}
