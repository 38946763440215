import { Avatar, Box, Typography } from '@mui/joy';
import { ReactElement } from 'react';
import {
	TaskAssigned,
	TaskHidden,
	TaskMap,
	TaskStatusIndicator,
	TaskTimeIndicator
} from '../../tasks/components';
import Card from '@mui/joy/Card';
import { useTaskLogs, useTask } from '../../tasks/providers';
import Divider from '@mui/joy/Divider';
import { TaskLogList } from '../../tasks/components/task-log-list';

export function _TaskIdLoadedPage(): ReactElement {
	const { task } = useTask();
	const { taskLogs } = useTaskLogs();

	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1,
				justifyContent: 'center'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flex: 1,
					flexDirection: 'row',
					gap: '24px',
					maxWidth: {
						xs: '100%',
						sm: '1200px'
					}
				}}
			>
				{/* Task data */}
				<Card
					sx={{
						maxWidth: { md: '300px' },
						flex: 1,
						padding: '24px',
						gap: '24px'
					}}
				>
					<Box sx={{ display: 'flex', gap: '24px', flexDirection: 'row' }}>
						<Avatar
							size="lg"
							alt={`${task.user?.firstName ?? ''} ${task.user?.lastName ?? ''}`}
							src={task.user?.image ?? ''}
						/>
						<Box>
							{task.user ? (
								<>
									<Typography fontSize="lg" fontWeight="lg">
										{`${task.user.firstName} ${task.user.lastName}`}
									</Typography>
									<Typography fontSize="sm">{`${task.user.phone}`}</Typography>
								</>
							) : (
								<Typography fontSize="lg">Unknown user</Typography>
							)}
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							gap: '8px'
						}}
					>
						<Box>
							<Typography fontSize="sm" color="neutral">
								Time
							</Typography>
							<TaskTimeIndicator task={task} />
						</Box>
						<Box>
							<Typography fontSize="sm" color="neutral">
								Activity
							</Typography>
							<Typography>
								{task.taskType === 'timed' ? task.name : 'Instant alert'}
							</Typography>
						</Box>
						{task.user?.distressPhrase && (
							<Box>
								<Typography fontSize="sm" color="neutral">
									Distress phrase
								</Typography>
								<Typography>{task.user?.distressPhrase}</Typography>
							</Box>
						)}

						<Box>
							<Typography fontSize="sm" color="neutral">
								Status
							</Typography>
							<TaskStatusIndicator task={task} />
						</Box>
					</Box>
					<Divider />
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							gap: '8px'
						}}
					>
						<Box>
							<Typography fontSize="sm" color="neutral">
								Assigned operators
							</Typography>
							<TaskAssigned task={task} />
						</Box>
					</Box>
					<Divider />
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							gap: '8px'
						}}
					>
						<Box>
							<TaskHidden task={task} />
						</Box>
					</Box>
					<Divider />
					<Box
						sx={{
							display: 'flex',
							flex: 1,
							flexDirection: 'column'
						}}
					>
						<Typography fontSize="sm" color="neutral">
							History
						</Typography>
						<TaskLogList taskLogs={taskLogs} task={task} />
					</Box>
				</Card>

				{/* Task map */}
				<Card
					sx={{
						flex: 1,
						padding: '0px',
						overflow: 'hidden'
					}}
				>
					<TaskMap />
				</Card>
			</Box>
		</Box>
	);
}
