import { ListItem, Typography } from '@mui/joy';
import { TaskLog, User } from '@techshift/callie-arc-api-typescript';
import dayjs from 'dayjs';
import { ReactElement, useMemo } from 'react';
import { getLogTime } from '../../core/util/time';

export type TaskLogItemProps = {
	taskLog: TaskLog;
	user?: User | null;
};
export function TaskLogItem(props: TaskLogItemProps): ReactElement {
	const { taskLog, user } = props;

	function getLogTitle(): string {
		switch (taskLog.taskLogType) {
			case 'opened_timed':
				return 'Started activity';
			case 'timeout_updated':
				return 'Time updated';
			case 'closed':
				return 'Marked safe';
			case 'note':
				return 'Note added';
			case 'watching':
				return 'Guardian watching';
			case 'arc_assigned':
				return 'ARC assigned';
			case 'arc_unassigned':
				return 'ARC unassigned';
			case 'opened_instant_alert':
			case 'alerted_timeout':
			case 'alerted_manual_app':
			case 'alerted_manual_button':
				return 'Alert triggered';
			default:
				return 'Unknown';
		}
	}

	function getLogBody(): string {
		switch (taskLog.taskLogType) {
			case 'opened_timed':
				let str = '';
				if (taskLog.taskName) {
					if (user) {
						str = `${user.firstName} started activity: "${taskLog.taskName}"`;
					} else {
						str = `Started activity: "${taskLog.taskName}"`;
					}
				} else {
					str = 'Started activity';
				}

				if (taskLog.note) {
					str += ` with note: "${taskLog.note}"`;
				}

				return str;
			case 'closed':
				if (user) {
					return `${user.firstName} marked themselves as safe`;
				}
				return '';
			case 'note':
				if (user) {
					return `${user.firstName} added a note: "${taskLog.note}"`;
				} else {
					return `Note: "${taskLog.note}"`;
				}
			case 'timeout_updated':
				const timeoutAt = taskLog.timeoutAt;
				if (timeoutAt) {
					const timeoutAtDate = dayjs(timeoutAt);
					return `Updated to ${timeoutAtDate.format('DD MMM YY h:mma')}`;
				}
				return '';
			case 'watching':
				if (!user) {
					return 'Unknown guardian has opened the link';
				}
				const guardianPhone = taskLog.guardianPhone;
				const guardian = user?.guardians.find((g) => g.phone === guardianPhone);
				let name: string;
				if (guardian) {
					name = guardian.name;
				} else {
					name = 'a deleted guardian';
				}
				return `Guardian "${name}" has opened the link`;
			case 'arc_assigned':
				return 'An operator has been assigned';
			case 'arc_unassigned':
				return 'An operator has been unassigned';
			case 'alerted_timeout':
				return 'Timeout alert';
			case 'alerted_manual_app':
			case 'alerted_manual_button':
				if (user) {
					return `${user.firstName} manually triggered an alert`;
				} else {
					return 'Manual alert';
				}
			default:
				return '';
		}
	}

	const title = getLogTitle();
	const body = getLogBody();
	const time = useMemo(() => {
		return getLogTime(new Date(taskLog.createdAt));
	}, [taskLog.createdAt]);

	return (
		<ListItem
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'flex-start'
			}}
		>
			<Typography fontSize="sm" fontWeight="bold">
				{title}
			</Typography>
			{body && (
				<Typography fontSize="sm" color="neutral">
					{body}
				</Typography>
			)}
			<Typography fontSize="xs" color="neutral">
				{time}
			</Typography>
		</ListItem>
	);
}
