import {
	createContext,
	useContext,
	ReactNode,
	ReactElement,
	useState,
	useEffect,
	useRef
} from 'react';
import assert from 'assert';
import { Battery } from '@techshift/callie-arc-api-typescript';
import { useOpenApi } from '../../core/providers';
import { usePrevious, useWebsocket } from '@techshift/react-core';

export interface BatteryContextData {
	battery: Battery | null;
	loading: boolean;
}

export const BatteryContext = createContext<BatteryContextData | undefined>(
	undefined
);

type BatteryProvderProps = {
	readonly taskId: string;
	readonly children?: ReactNode;
};
export function BatteryProvider(props: BatteryProvderProps): ReactElement {
	const { taskId, children } = props;

	const [loading, setLoading] = useState(false);
	const [battery, setBattery] = useState<Battery | null>(null);
	const [hasInitialFetched, setHasInitialFetched] = useState(false);

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);
	useEffect(() => {
		openApiRef.current = openApi;
	});

	const { subscribe, unsubscribe, on, off, authenticated } = useWebsocket();
	const prevAuthenticated = usePrevious(authenticated);

	/**
	 * Fetch data
	 */
	useEffect(() => {
		if (loading) {
			return;
		}

		let shouldFetch = false;

		// Always fetch if not done so already
		if (!hasInitialFetched) {
			shouldFetch = true;
		}

		// Always fetch if the socket has turned off and back on again (As we have a period of time where we don't get updates)
		if (authenticated && !prevAuthenticated) {
			shouldFetch = true;
		}

		if (!shouldFetch) {
			return;
		}

		function fetch() {
			return openApiRef.current.tasks
				.getTaskBatteries(taskId, {
					sortOrder: 'desc',
					limit: 1,
					offset: 0
				})
				.then((response) => {
					const data = response.data.data.batteries;
					if (data.data.length > 0) {
						setBattery(data.data[0]);
					}
				})
				.catch(() => {
					// Wait 5 seconds before retrying
					return new Promise((resolve) => {
						setTimeout(() => {
							resolve(fetch());
						}, 5000);
					});
				});
		}

		setLoading(true);
		fetch().finally(() => {
			setHasInitialFetched(true);
			setLoading(false);
		});
	}, [taskId, authenticated, hasInitialFetched, loading, prevAuthenticated]);

	/**
	 * Websockets
	 */
	useEffect(() => {
		const ids = subscribe([{ resource: `arc_tasks#${taskId}.batteries` }]);
		return () => {
			unsubscribe(ids);
		};
	}, [taskId, subscribe, unsubscribe]);

	useEffect(() => {
		const callback = (message: any) => {
			const batteries = message.data.batteries as Battery[];
			if (batteries.length > 0) {
				setBattery(batteries[0]);
			}
		};
		on('tasks_batteries', callback);

		return () => {
			off('tasks_batteries', callback);
		};
	}, [on, off, taskId]);

	return (
		<BatteryContext.Provider
			value={{
				battery,
				loading
			}}
		>
			{children}
		</BatteryContext.Provider>
	);
}

export function useBattery(): BatteryContextData {
	const data = useContext<BatteryContextData | undefined>(BatteryContext);

	assert(
		data,
		"Battery unavailable. Are you sure you're using this in a nested component of an BatteryProvider?"
	);

	return data;
}
