import { Typography } from '@mui/joy';
import { Task } from '@techshift/callie-arc-api-typescript';
import { ReactElement } from 'react';
import { useCountdownTimer } from '../hooks';

type TaskTimeIndicatorProps = {
	task: Task;
	textAlign?: 'left' | 'center' | 'right';
};
export function TaskTimeIndicator(props: TaskTimeIndicatorProps): ReactElement {
	const { task, textAlign = 'left' } = props;

	function shortDate(date: Date): string {
		return date.toLocaleDateString('en-GB', {
			year: 'numeric',
			month: 'short',
			day: 'numeric'
		});
	}

	function shortTime(date: Date): string {
		return date.toLocaleTimeString('en-GB', {
			hour: 'numeric',
			minute: 'numeric'
		});
	}

	function alertedTime(date: Date): string {
		const now = new Date();
		if (now.getDate() === date.getDate()) {
			return `Alerted at ${shortTime(date)} today`;
		} else {
			return `Alerted at ${shortTime(date)} ${shortDate(date)}`;
		}
	}

	if (task.status === 'open') {
		if (task.alertType !== null) {
			if (task.alertedAt) {
				return (
					<Typography fontWeight="lg" textAlign={textAlign}>
						{alertedTime(new Date(task.alertedAt))}
					</Typography>
				);
			}
		} else {
			if (task.taskType === 'timed' && task.timeoutAt) {
				return (
					<Countdown time={new Date(task.timeoutAt)} textAlign={textAlign} />
				);
			}
		}
	} else {
		if (task.closedAt) {
			return (
				<Typography fontWeight="lg" textAlign={textAlign}>
					{`Closed on ${shortDate(new Date(task.closedAt))}`}
				</Typography>
			);
		}
	}
	return <></>;
}

function Countdown(props: {
	time: Date;
	textAlign?: 'left' | 'center' | 'right';
}): ReactElement {
	const timer = useCountdownTimer({ timeoutAt: props.time });
	return (
		<Typography fontWeight="lg" textAlign={props.textAlign}>
			{timer}
		</Typography>
	);
}
