import { AxiosResponse } from 'axios';
import { LoginResponse } from '@techshift/callie-arc-api-typescript';
import { useNullableTokens } from '@techshift/react-core';
import { useOpenApi } from '../../core/providers';

type Result = {
	login: (options: {
		email: string;
		password: string;
	}) => Promise<AxiosResponse<LoginResponse>>;
};
export function useLoginService(): Result {
	const { openApi } = useOpenApi();
	const { setTokens } = useNullableTokens();

	return {
		login: ({
			email,
			password
		}: {
			email: string;
			password: string;
		}): Promise<AxiosResponse<LoginResponse>> => {
			return openApi.auth
				.login({ email, password })
				.then((response) => {
					const { accessToken, refreshToken } = response.data.data;
					setTokens({ accessToken, refreshToken });
					return response;
				})
				.catch((error) => {
					throw error;
				});
		}
	};
}
