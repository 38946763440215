/* eslint-disable import/no-webpack-loader-syntax */
import 'mapbox-gl/dist/mapbox-gl.css';
import { ReactElement, useEffect, useRef, useState } from 'react';
import Map, { Layer, MapRef, Marker, Source } from 'react-map-gl';
import styles from './task-map-view.module.scss';
import { Battery, Location } from '@techshift/callie-arc-api-typescript';
import { getLocaleRegionBoundingBox, locationsToBounds } from './helpers';
import { MapMarker } from './map-marker';
import { UserMapMarker } from './user-map-marker';
import { FocusButton } from './focus-button';
import { LocationBottomBar } from './location-bottom-bar';
import circle from '@turf/circle';

// Fix from https://github.com/visgl/react-map-gl/issues/1266#issuecomment-753686953
// @ts-ignore
import mapboxgl from '!mapbox-gl';
mapboxgl.workerClass =
	require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const region = getLocaleRegionBoundingBox();

type TaskMapViewProps = {
	taskStatus: 'open' | 'closed';
	locations: Location[];
	userImage: string;
	battery: Battery | null;
	loading: boolean;
};

export function TaskMapView(props: TaskMapViewProps): ReactElement {
	const { taskStatus, locations, userImage, battery, loading } = props;

	const mapRef = useRef<MapRef>();
	const [mapLoaded, setMapLoaded] = useState(false);

	// Separate state for latest location to avoid re-centering the map on every location update
	const [startLatitude, setStartLatitude] = useState<number | null>(null);
	const [startLongitude, setStartLongitude] = useState<number | null>(null);
	const [startTimestamp, setStartTimestamp] = useState<string | null>(null);

	const [endLatitude, setEndLatitude] = useState<number | null>(null);
	const [endLongitude, setEndLongitude] = useState<number | null>(null);
	const [endTimestamp, setEndTimestamp] = useState<string | null>(null);

	const [latestLatitude, setLatestLatitude] = useState<number | null>(null);
	const [latestLongitude, setLatestLongitude] = useState<number | null>(null);
	const [latestAccuracy, setLatestAccuracy] = useState<number | null>(null);
	const [, setLatestTimestamp] = useState<string | null>(null);

	const [routeCoordinates, setRouteCoordinates] = useState<[number, number][]>(
		[]
	);

	const [focused, setFocused] = useState(true);

	// Control route coordinates
	useEffect(() => {
		if (locations.length === 0) {
			return;
		}

		setRouteCoordinates(
			locations.map((location) => [location.longitude, location.latitude])
		);
	}, [locations]);

	// Control start marker
	useEffect(() => {
		if (locations.length === 0) {
			setStartLatitude(null);
			setStartLongitude(null);
			setStartTimestamp(null);
			return;
		}

		if (!loading) {
			// Finished loading means all locations have been loaded. Take the oldest location as the start location.
			const startLocation = locations[locations.length - 1];
			setStartLatitude(startLocation.latitude);
			setStartLongitude(startLocation.longitude);
			setStartTimestamp(startLocation.timestamp);
		}
	}, [locations, loading]);

	// Control end marker
	useEffect(() => {
		if (locations.length === 0 || taskStatus === 'open') {
			setEndLatitude(null);
			setEndLongitude(null);
			setEndTimestamp(null);
			return;
		}

		setEndLatitude(locations[0].latitude);
		setEndLongitude(locations[0].longitude);
		setEndTimestamp(locations[0].timestamp);
	}, [locations, taskStatus]);

	// Control latest marker
	useEffect(() => {
		if (locations.length === 0 || taskStatus === 'closed') {
			setLatestLatitude(null);
			setLatestLongitude(null);
			setLatestAccuracy(null);
			setLatestTimestamp(null);
			return;
		}

		setLatestLatitude(locations[0].latitude);
		setLatestLongitude(locations[0].longitude);
		setLatestAccuracy(locations[0].horizontalAccuracy || null);
		setLatestTimestamp(locations[0].timestamp);
	}, [locations, taskStatus]);

	// Follow latest marker
	useEffect(() => {
		if (!focused) {
			return;
		}
		if (taskStatus !== 'open') {
			return;
		}
		if (!latestLatitude || !latestLongitude) {
			return;
		}
		if (!mapLoaded) {
			return;
		}

		mapRef.current?.setCenter([latestLongitude, latestLatitude]);
		mapRef.current?.setZoom(17);
	}, [latestLatitude, latestLongitude, focused, taskStatus, mapLoaded]);

	// Follow entire route
	useEffect(() => {
		if (!focused) {
			return;
		}
		if (taskStatus !== 'closed') {
			return;
		}
		if (locations.length === 0) {
			return;
		}
		// Only fit bounds when all of the locations have been loaded
		if (loading) {
			return;
		}
		if (!mapLoaded) {
			return;
		}

		const bounds = locationsToBounds(locations);
		mapRef.current?.fitBounds(
			[bounds.sw.lon, bounds.sw.lat, bounds.ne.lon, bounds.ne.lat],
			{ padding: 50, maxZoom: 17, animate: true }
		);
	}, [locations, focused, taskStatus, loading, mapLoaded]);

	return (
		<div className={styles.container}>
			<Map
				ref={mapRef as any}
				initialViewState={{
					bounds: [region.sw.lon, region.sw.lat, region.ne.lon, region.ne.lat]
				}}
				style={{ width: '100%', height: '100%' }}
				mapStyle="mapbox://styles/mapbox/streets-v11"
				mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
				onDragStart={() => setFocused(false)}
				onTouchMove={() => setFocused(false)}
				onZoomStart={(e) => {
					if (e.originalEvent) {
						setFocused(false);
					}
				}}
				onLoad={() => setMapLoaded(true)}
			>
				{latestLongitude && latestLatitude && (
					<Marker
						longitude={latestLongitude}
						latitude={latestLatitude}
						anchor={'bottom'}
						style={{
							zIndex: 1
						}}
					>
						<UserMapMarker image={userImage} battery={battery} />
					</Marker>
				)}

				{latestLongitude && latestLatitude && latestAccuracy && (
					<Source
						id="circleSource"
						type="geojson"
						data={circle([latestLongitude, latestLatitude], latestAccuracy, {
							steps: 100,
							units: 'meters'
						})}
					>
						<Layer
							id="circleLayer"
							type="fill"
							paint={{
								'fill-color': '#007cbf',
								'fill-opacity': 0.3
							}}
						/>
					</Source>
				)}

				{startLatitude && startLongitude && startTimestamp && (
					<MapMarker
						type={'start'}
						longitude={startLongitude}
						latitude={startLatitude}
						timestamp={startTimestamp}
					/>
				)}

				{endLatitude && endLongitude && endTimestamp && (
					<MapMarker
						type={'finish'}
						longitude={endLongitude}
						latitude={endLatitude}
						timestamp={endTimestamp}
					/>
				)}

				<Source
					id="route"
					type="geojson"
					data={{
						type: 'Feature',
						properties: {},
						geometry: {
							type: 'LineString',
							coordinates: routeCoordinates
						}
					}}
				>
					<Layer
						id="route"
						source="route"
						type="line"
						layout={{ 'line-join': 'round', 'line-cap': 'round' }}
						paint={{
							'line-color': '#272727',
							'line-width': 3
						}}
					/>
				</Source>
			</Map>
			<div className={styles.focusButtonContainer}>
				<FocusButton
					onClick={() => {
						setFocused(!focused);
					}}
					focused={focused}
				/>
			</div>

			{!loading && taskStatus === 'open' && (
				<div className={styles.bottomInfoBarContainer}>
					<LocationBottomBar locations={locations} battery={battery} />
				</div>
			)}
		</div>
	);
}
