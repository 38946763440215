import * as React from 'react';
import Box, { BoxProps } from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';

const Root = (props: BoxProps) => (
	<Box
		{...props}
		sx={[
			{
				bgcolor: 'background.bodyEmail',
				display: 'grid',
				gridTemplateColumns: {
					xs: '1fr',
					sm: 'minmax(64px, 200px) minmax(450px, 1fr)'
				},
				gridTemplateRows: '64px 1fr',
				minHeight: '100vh'
			},
			...(Array.isArray(props.sx) ? props.sx : [props.sx])
		]}
	/>
);

const Header = (props: BoxProps) => (
	<Box
		component="header"
		className="Header"
		{...props}
		sx={[
			{
				p: 2,
				gap: 2,
				bgcolor: 'background.componentBg',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				gridColumn: '1 / -1',
				borderBottom: '1px solid',
				borderColor: 'divider',
				position: 'sticky',
				top: 0,
				zIndex: 1100
			},
			...(Array.isArray(props.sx) ? props.sx : [props.sx])
		]}
	/>
);

const SideNav = (props: BoxProps) => (
	<Box
		component="nav"
		className="Navigation"
		{...props}
		sx={[
			{
				p: 2,
				bgcolor: 'background.componentBg',
				borderRight: '1px solid',
				borderColor: 'divider',
				// position: 'sticky',
				// top: '500px',
				display: {
					xs: 'none',
					sm: 'initial'
				}
			},
			...(Array.isArray(props.sx) ? props.sx : [props.sx])
		]}
	/>
);

const Main = (props: BoxProps) => (
	<Box
		component="main"
		className="Main"
		{...props}
		sx={[
			{ p: 2, display: 'flex' },
			...(Array.isArray(props.sx) ? props.sx : [props.sx])
		]}
	/>
);

const SideDrawer = ({
	onClose,
	...props
}: BoxProps & { onClose: React.MouseEventHandler<HTMLDivElement> }) => (
	<Box
		{...props}
		sx={[
			{ position: 'fixed', zIndex: 1200, width: '100%', height: '100%' },
			...(Array.isArray(props.sx) ? props.sx : [props.sx])
		]}
	>
		<Box
			role="button"
			onClick={onClose}
			sx={{
				position: 'absolute',
				inset: 0,
				bgcolor: (theme) =>
					`rgba(${theme.vars.palette.neutral.darkChannel} / 0.8)`
			}}
		/>
		<Sheet
			sx={{
				minWidth: 256,
				width: 'max-content',
				height: '100%',
				p: 2,
				boxShadow: 'lg',
				bgcolor: 'background.componentBg'
			}}
		>
			{props.children}
		</Sheet>
	</Box>
);

const Layout = {
	Root,
	Header,
	SideNav,
	SideDrawer,
	Main
};
export default Layout;
