import { ReactElement, useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Task } from '@techshift/callie-arc-api-typescript';
import { parseApiError } from '@techshift/react-core';
import { _TaskIdLoadedPage } from './_task-id-loaded.page';
import {
	BatteryProvider,
	LocationsProvider,
	TaskLogsProvider,
	TaskProvider
} from '../../tasks/providers';
import { useOpenApi } from '../../core/providers';
import toast from 'react-hot-toast';
import { Box, Button, Typography } from '@mui/joy';

export function TaskIdPage(): ReactElement {
	const { taskId } = useParams();

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);
	useEffect(() => {
		openApiRef.current = openApi;
	});

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [task, setTask] = useState<Task | null>(null);

	const fetch = useCallback((id: string) => {
		setTask(null);
		setLoading(true);
		setError('');
		openApiRef.current.tasks
			.getTask(id)
			.then((response) => {
				setTask(response.data.data.task);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'TASK_NOT_FOUND') {
							setError('Task not found');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		if (error) {
			toast.error(error);
		}
	}, [error]);

	useEffect(() => {
		if (!taskId) {
			return;
		}

		fetch(taskId);
	}, [taskId, fetch]);

	if (loading) {
		return <Typography>Loading</Typography>;
	}

	if (error) {
		return (
			<Box
				sx={{
					display: 'flex',
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column'
				}}
			>
				<Typography>Error</Typography>
				<Typography>{error}</Typography>
				<Button
					onClick={() => {
						if (taskId) {
							fetch(taskId);
						}
					}}
				>
					Retry
				</Button>
			</Box>
		);
	}

	if (task) {
		return (
			<TaskProvider task={task}>
				<TaskLogsProvider taskId={task.id}>
					<LocationsProvider taskId={task.id}>
						<BatteryProvider taskId={task.id}>
							<_TaskIdLoadedPage />
						</BatteryProvider>
					</LocationsProvider>
				</TaskLogsProvider>
			</TaskProvider>
		);
	}

	// Should never reach here
	return <></>;
}
