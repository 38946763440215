import { ReactElement, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Layout from '../layout/layout';
import { TopNavigation } from '../navigation/top-navigation';
import SideNavigation from '../navigation/side-navigation';

export function MainPage(): ReactElement {
	const [drawerOpen, setDrawerOpen] = useState(false);

	return (
		<>
			{drawerOpen && (
				<Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
					<SideNavigation />
				</Layout.SideDrawer>
			)}
			<Layout.Root
				sx={{
					...(drawerOpen && {
						height: '100vh',
						overflow: 'hidden'
					})
				}}
			>
				<Layout.Header>
					<TopNavigation setDrawerOpen={setDrawerOpen} />
				</Layout.Header>
				<Layout.SideNav>
					<SideNavigation />
				</Layout.SideNav>
				<Layout.Main>
					<Outlet />
				</Layout.Main>
			</Layout.Root>
		</>
	);
}
