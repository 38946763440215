import { ReactElement } from 'react';
import { useBattery, useLocations, useTask } from '../../providers';
import { TaskMapView } from './task-map-view';

export function TaskMap(): ReactElement {
	const { task } = useTask();
	const { locations, loading } = useLocations();
	const { battery } = useBattery();

	return (
		<TaskMapView
			taskStatus={task.status}
			locations={locations}
			userImage={task.user?.image || ''}
			loading={loading}
			battery={battery}
		/>
	);
}
