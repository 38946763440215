import { Battery } from '@techshift/callie-arc-api-typescript';
import { ReactElement } from 'react';
import styles from './user-map-marker.module.scss';
import { Typography } from '@mui/joy';

export type UserMapMarkerProps = {
	image: string;
	battery: Battery | null;
};

export function UserMapMarker(props: UserMapMarkerProps): ReactElement {
	const { image, battery } = props;

	let batteryFillColor = 'black';
	if (battery) {
		if (battery.isCharging) {
			batteryFillColor = 'green';
		} else if (battery.level <= 20) {
			batteryFillColor = 'red';
		}
	}

	return (
		<div className={styles.markerContainer}>
			<div className={styles.contentContainer}>
				<img src={image} className={styles.image} alt="User" />

				{battery !== null && (
					<>
						<div className={styles.batteryContainer}>
							{battery.isCharging && (
								<div className={styles.zap}>
									<svg
										fill="#000000"
										width="100%"
										height="100%"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M20.98 11.802a.995.995 0 0 0-.738-.771l-6.86-1.716 2.537-5.921a.998.998 0 0 0-.317-1.192.996.996 0 0 0-1.234.024l-11 9a1 1 0 0 0 .39 1.744l6.719 1.681-3.345 5.854A1.001 1.001 0 0 0 8 22a.995.995 0 0 0 .6-.2l12-9a1 1 0 0 0 .38-.998z" />
									</svg>
								</div>
							)}
							<div className={styles.battery}>
								<div
									className={styles.batteryFill}
									style={{
										width: `${battery.level}%`,
										backgroundColor: batteryFillColor
									}}
								/>
							</div>
							<div className={styles.batteryTip} />
						</div>

						<div className={styles.percentContainer}>
							<Typography>{`${battery.level}%`}</Typography>
						</div>
					</>
				)}
			</div>
			<div className={styles.arrow} />
		</div>
	);
}
