import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import { NavLink } from 'react-router-dom';

// Icons import
import InboxRoundedIcon from '@mui/icons-material/InboxRounded';

export default function SideNavigation() {
	return (
		<List
			size="sm"
			sx={{
				'--List-item-radius': '8px',
				position: 'sticky',
				top: '80px'
			}}
		>
			<ListItem nested sx={{ p: 0, padding: '8px' }}>
				<Box
					sx={{
						mb: 1,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<Typography
						id="nav-list-browse"
						textColor="neutral.500"
						fontWeight={700}
						sx={{
							fontSize: '10px',
							textTransform: 'uppercase',
							letterSpacing: '.1rem'
						}}
					>
						Tasks
					</Typography>
				</Box>
				<List
					aria-labelledby="nav-list-browse"
					sx={{
						'& .JoyListItemButton-root': { p: '8px' },
						'& [role="button"]': {
							borderRadius: '8px',
							padding: '8px'
						}
					}}
				>
					<NavLink to="/tasks" end style={{ textDecoration: 'none' }}>
						{({ isActive }) => (
							<ListItem>
								<ListItemButton
									variant={isActive ? 'soft' : 'plain'}
									color="primary"
								>
									<ListItemDecorator sx={{ color: 'inherit' }}>
										<InboxRoundedIcon fontSize="small" />
									</ListItemDecorator>
									<ListItemContent>Alerted</ListItemContent>
								</ListItemButton>
							</ListItem>
						)}
					</NavLink>
					<NavLink to="/tasks/all" style={{ textDecoration: 'none' }}>
						{({ isActive }) => (
							<ListItem>
								<ListItemButton
									variant={isActive ? 'soft' : 'plain'}
									color="primary"
								>
									<ListItemDecorator sx={{ color: 'inherit' }}>
										<InboxRoundedIcon fontSize="small" />
									</ListItemDecorator>
									<ListItemContent>All</ListItemContent>
								</ListItemButton>
							</ListItem>
						)}
					</NavLink>
				</List>
			</ListItem>
		</List>
	);
}
