import {
	createContext,
	useContext,
	ReactElement,
	ReactNode,
	useMemo
} from 'react';
import assert from 'assert';
import { Api } from '@techshift/callie-arc-api-typescript';

export interface OpenApiContextData {
	openApi: Api<unknown>;
}

const OpenApiContext = createContext<OpenApiContextData | undefined>(undefined);

type Props = {
	readonly children?: ReactNode;
};

export function OpenApiProvider({ children }: Props): ReactElement {
	const openApi = useMemo(() => {
		return new Api();
	}, []);
	return (
		<OpenApiContext.Provider value={{ openApi }}>
			{children}
		</OpenApiContext.Provider>
	);
}

export function useOpenApi(): OpenApiContextData {
	const data = useContext<OpenApiContextData | undefined>(OpenApiContext);

	assert(
		data,
		"OpenApi unavailable. Are you sure you're using this in a nested component of an OpenApiProvider?"
	);

	return data;
}
