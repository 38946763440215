import {
	createContext,
	useContext,
	ReactNode,
	ReactElement,
	useRef,
	useEffect,
	useState,
	useMemo
} from 'react';
import assert from 'assert';
import { Task } from '@techshift/callie-arc-api-typescript';
import { useOpenApi } from '../../core/providers';
import { usePrevious, useWebsocket } from '@techshift/react-core';
import { datadogLogs } from '@datadog/browser-logs';

const LIMIT = 25;

function filterTask(task: Task): boolean {
	return task.status === 'open' && task.alertedAt !== null && !task.arcHidden;
}
function filterTasks(tasks: Task[]): Task[] {
	return tasks.filter(filterTask);
}

export interface AlertedTasksContextData {
	tasks: Task[];
}

export const AlertedTasksContext = createContext<
	AlertedTasksContextData | undefined
>(undefined);

type AlertedTasksProvderProps = {
	readonly children?: ReactNode;
};
export function AlertedTasksProvider(
	props: AlertedTasksProvderProps
): ReactElement {
	const { children } = props;

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);
	useEffect(() => {
		openApiRef.current = openApi;
	});

	const { subscribe, unsubscribe, on, off, authenticated } = useWebsocket();
	const prevAuthenticated = usePrevious(authenticated);

	const [loading, setLoading] = useState(false);
	const [taskMap, setTasksMap] = useState<Record<string, Task>>({});
	const [hasInitialFetched, setHasInitialFetched] = useState(false);

	/**
	 *  Fetch task data
	 */
	useEffect(() => {
		if (loading) {
			return;
		}

		let shouldFetch = false;

		// Always fetch if not done so already
		if (!hasInitialFetched) {
			shouldFetch = true;
		}

		// Always fetch if the socket has turned off and back on again (As we have a period of time where we don't get updates)
		if (authenticated && !prevAuthenticated) {
			shouldFetch = true;
		}

		if (!shouldFetch) {
			return;
		}

		async function fetch(offset: number): Promise<void> {
			return openApiRef.current.tasks
				.getTasks({
					limit: LIMIT,
					offset,
					status: 'open',
					isAlerted: true
				})
				.then((response) => {
					const data = response.data.data.tasks;

					if (data.data.length > 0) {
						const tasksWeWant = filterTasks(data.data);

						setTasksMap((currentTasksMap) => {
							const newMap = tasksWeWant.reduce((acc, task) => {
								acc[task.id] = task;
								return acc;
							}, {} as Record<string, Task>);
							return { ...currentTasksMap, ...newMap };
						});
					}
					if (data.hasMore) {
						return fetch(offset + 1);
					}
				})
				.catch(() => {
					return new Promise((resolve) => {
						setTimeout(() => {
							resolve(fetch(offset));
						}, 5000);
					});
				});
		}

		setLoading(true);
		fetch(0).finally(() => {
			setHasInitialFetched(true);
			setLoading(false);
		});
	}, [authenticated, hasInitialFetched, loading, prevAuthenticated]);

	/**
	 * Task websockets
	 */
	useEffect(() => {
		const ids = subscribe([{ resource: `arc_tasks#all` }]);
		return () => {
			unsubscribe(ids);
		};
	}, [subscribe, unsubscribe]);

	useEffect(() => {
		const tasksCallback = (message: any) => {
			const task: Task = message.data.task;
			if (filterTask(task)) {
				setTasksMap((current) => {
					const newMap = { ...current, [task.id]: task };
					datadogLogs.logger.info(
						'TASK_ANALYTICS: Alerted task socket update',
						{
							taskId: task.id,
							newTasks: newMap,
							oldTasks: current
						}
					);
					return newMap;
				});
			} else {
				setTasksMap((current) => {
					const newMap = { ...current };
					if (current[task.id]) {
						delete newMap[task.id];
					}
					datadogLogs.logger.info(
						'TASK_ANALYTICS: Not-alerted task socket update',
						{
							taskId: task.id,
							newTasks: newMap,
							oldTasks: current
						}
					);
					return newMap;
				});
			}
		};
		on('tasks', tasksCallback);

		return () => {
			off('tasks', tasksCallback);
		};
	}, [on, off]);

	const tasks = useMemo(() => {
		const _tasks = Object.values(taskMap).sort((a, b) => {
			if (a.primarySortIndex !== b.primarySortIndex) {
				return a.primarySortIndex - b.primarySortIndex;
			} else {
				return (
					new Date(b.secondarySortIndex).getTime() -
					new Date(a.secondarySortIndex).getTime()
				);
			}
		});

		return _tasks;
	}, [taskMap]);

	return (
		<AlertedTasksContext.Provider
			value={{
				tasks
			}}
		>
			{children}
		</AlertedTasksContext.Provider>
	);
}

export function useAlertedTasks(): AlertedTasksContextData {
	const data = useContext<AlertedTasksContextData | undefined>(
		AlertedTasksContext
	);

	assert(
		data,
		"AlertedTasks unavailable. Are you sure you're using this in a nested component of an AlertedTasksProvider?"
	);

	return data;
}
