import { ReactElement } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { EnvProvider, NullableTokensProvider } from '@techshift/react-core';
import {
	TasksAlertedPage,
	TaskIdPage,
	LoginPage,
	MainPage,
	NotFoundPage,
	TasksAllPage
} from './pages';
import {
	AppAuthInit,
	AppIndexRedirect,
	AppUnauthInit
} from './core/components';
import { GlobalStyles } from '@mui/system';
import { CssVarsProvider } from '@mui/joy';
import filesTheme from './theme';
import type { Theme } from '@mui/joy/styles';
import { Toaster } from 'react-hot-toast';
import { datadogLogs } from '@datadog/browser-logs';
import { DataDogUserSync } from './core/providers';

datadogLogs.init({
	clientToken: process.env.REACT_APP_DATADOG_TOKEN || '',
	site: 'datadoghq.eu',
	forwardErrorsToLogs: true,
	sessionSampleRate: 100,
	service: 'callie-arc',
	env: process.env.NODE_ENV,
	version: process.env.REACT_APP_VERSION
});

function App(): ReactElement {
	const defaultBaseUrl = process.env.REACT_APP_BASE_URL || '';

	return (
		<CssVarsProvider disableTransitionOnChange theme={filesTheme}>
			<GlobalStyles<Theme>
				styles={(theme) => ({
					body: {
						margin: 0,
						fontFamily: theme.vars.fontFamily.body
					}
				})}
			/>
			<EnvProvider defaultBaseUrl={defaultBaseUrl}>
				<NullableTokensProvider>
					<DataDogUserSync>
						<Routes>
							<Route path="/" element={<AppIndexRedirect />} />

							<Route element={<AppUnauthInit />}>
								<Route path="login" element={<LoginPage />} />
							</Route>

							<Route path="/not-found" element={<NotFoundPage />} />
							<Route path="*" element={<Navigate to="/not-found" />} />

							<Route
								element={
									<AppAuthInit>
										<MainPage />
									</AppAuthInit>
								}
							>
								<Route index element={<TasksAlertedPage />} />
								<Route path="tasks" element={<TasksAlertedPage />} />
								<Route path="tasks/all" element={<TasksAllPage />} />
								<Route path="tasks/:taskId" element={<TaskIdPage />} />
							</Route>
						</Routes>
					</DataDogUserSync>
				</NullableTokensProvider>
			</EnvProvider>
			<Toaster />
		</CssVarsProvider>
	);
}

export default App;
