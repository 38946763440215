import { useNullableTokens } from '@techshift/react-core';
import { ReactElement, ReactNode, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { datadogLogs } from '@datadog/browser-logs';

type DataDogUserProps = {
	children: ReactNode;
};
export function DataDogUserSync({ children }: DataDogUserProps): ReactElement {
	const { tokens } = useNullableTokens();

	useEffect(() => {
		if (tokens?.refreshToken) {
			const decoded = jwt_decode(tokens.refreshToken) as { sub: string };
			datadogLogs.setUser({ id: decoded.sub });
			datadogLogs.logger.log('User logged in');
		} else {
			datadogLogs.clearUser();
			datadogLogs.logger.log('User logged out');
		}
	}, [tokens?.refreshToken]);

	return <>{children}</>;
}
