import { ReactElement, useState } from 'react';
import { useNullableTokens } from '@techshift/react-core';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import MenuItem from '@mui/joy/MenuItem';

// Icons
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import AlertIcon from '@mui/icons-material/NotificationImportantRounded';

export type TopNavigationProps = {
	setDrawerOpen: (open: boolean) => void;
};
export function TopNavigation(props: TopNavigationProps): ReactElement {
	const { setDrawerOpen } = props;
	const { deleteTokens } = useNullableTokens();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		deleteTokens();
	};

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: 1.5
				}}
			>
				<IconButton
					variant="outlined"
					size="sm"
					onClick={() => setDrawerOpen(true)}
					sx={{ display: { sm: 'none' } }}
				>
					<MenuIcon fontSize="small" />
				</IconButton>
				<IconButton
					size="sm"
					variant="solid"
					sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
				>
					<AlertIcon fontSize="small" />
				</IconButton>
				<Typography fontWeight={700}>Callie ARC</Typography>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5 }}>
				<IconButton
					id="nav-settings-button"
					size="sm"
					sx={{ display: { xs: 'inline-flex' } }}
					variant="outlined"
					color="primary"
					onClick={handleClick}
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
				>
					<SettingsIcon fontSize="small" />
				</IconButton>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					aria-labelledby="nav-settings-button"
					placement="bottom-end"
					sx={{
						zIndex: 1200
					}}
				>
					<MenuItem onClick={handleLogout}>
						<ListItemDecorator>
							<LogoutIcon />
						</ListItemDecorator>{' '}
						Logout
					</MenuItem>
				</Menu>
			</Box>
		</>
	);
}
