import {
	createContext,
	useContext,
	ReactNode,
	ReactElement,
	useState,
	useEffect,
	useRef,
	useCallback
} from 'react';
import assert from 'assert';
import { Location } from '@techshift/callie-arc-api-typescript';
import { useOpenApi } from '../../core/providers';
import { usePrevious, useWebsocket } from '@techshift/react-core';

const LIMIT = 2000;

export interface LocationsContextData {
	locations: Location[];
	loading: boolean;
}

export const LocationsContext = createContext<LocationsContextData | undefined>(
	undefined
);

type LocationsProvderProps = {
	readonly taskId: string;
	readonly children?: ReactNode;
};
export function LocationsProvider(props: LocationsProvderProps): ReactElement {
	const { taskId, children } = props;

	const [loading, setLoading] = useState(false);
	const [locations, _setLocations] = useState<Location[]>([]);
	const [hasInitialFetched, setHasInitialFetched] = useState(false);

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);
	useEffect(() => {
		openApiRef.current = openApi;
	});

	const { subscribe, unsubscribe, on, off, authenticated } = useWebsocket();
	const prevAuthenticated = usePrevious(authenticated);

	const sortAndAppendLocations = useCallback((newLocations: Location[]) => {
		_setLocations((prev) => {
			function uniqueLocations(__locations: Location[]): Location[] {
				const locationMap: { [timestamp: string]: Location } = {};
				__locations.forEach((location) => {
					locationMap[location.timestamp] = location;
				});
				return Object.values(locationMap);
			}
			function sortLocations(__locations: Location[]): Location[] {
				return __locations.sort((a, b) =>
					b.timestamp < a.timestamp ? -1 : b.timestamp > a.timestamp ? 1 : 0
				);
			}

			if (prev.length === 0) {
				const _locs = sortLocations(uniqueLocations(newLocations));
				return _locs;
			}

			if (newLocations.length === 0) {
				return prev;
			}

			const sortedNewLocations = sortLocations(newLocations);

			// Figure out if the new chunk can be placed at the start or end of the prev locations. If neither, then sort the entire array.
			const prevStart = prev[0].timestamp;
			const prevEnd = prev[prev.length - 1].timestamp;

			const newStart = newLocations[0].timestamp;
			const newEnd = newLocations[newLocations.length - 1].timestamp;

			if (newStart < prevEnd) {
				return [...prev, ...sortedNewLocations];
			}

			if (newEnd > prevStart) {
				return [...sortedNewLocations, ...prev];
			}
			return sortLocations(uniqueLocations([...sortedNewLocations, ...prev]));
		});
	}, []);

	/**
	 *  Fetch location data
	 */
	useEffect(() => {
		if (loading) {
			return;
		}

		let shouldFetch = false;

		// Always fetch if not done so already
		if (!hasInitialFetched) {
			shouldFetch = true;
		}

		// Always fetch if the socket has turned off and back on again (As we have a period of time where we don't get updates)
		if (authenticated && !prevAuthenticated) {
			shouldFetch = true;
		}

		if (!shouldFetch) {
			return;
		}

		async function fetch(offset: number): Promise<void> {
			return openApiRef.current.tasks
				.getTaskLocations(taskId, {
					sortOrder: 'desc',
					limit: LIMIT,
					offset: offset * LIMIT
				})
				.then((response) => {
					const data = response.data.data.locations;
					if (data.data.length > 0) {
						sortAndAppendLocations(data.data);
					}
					if (data.hasMore) {
						return fetch(offset + 1);
					}
				})
				.catch(() => {
					// Wait 5 seconds before retrying
					return new Promise((resolve) => {
						setTimeout(() => {
							resolve(fetch(offset));
						}, 5000);
					});
				});
		}

		setLoading(true);
		fetch(0).finally(() => {
			setHasInitialFetched(true);
			setLoading(false);
		});
		// UIToast.promise(promise, {
		// 	loading: t('loading_locations'),
		// 	success: t('locations_success'),
		// 	error: t('locations_error')
		// });
	}, [
		taskId,
		authenticated,
		hasInitialFetched,
		loading,
		prevAuthenticated,
		sortAndAppendLocations
	]);

	/**
	 * Location websockets
	 */
	useEffect(() => {
		const ids = subscribe([{ resource: `arc_tasks#${taskId}.locations` }]);
		return () => {
			unsubscribe(ids);
		};
	}, [taskId, subscribe, unsubscribe]);

	useEffect(() => {
		const callback = (message: any) => {
			const _locations = message.data.locations as Location[];
			sortAndAppendLocations(_locations);
		};
		on('tasks_locations', callback);

		return () => {
			off('tasks_locations', callback);
		};
	}, [on, off, taskId, sortAndAppendLocations]);

	return (
		<LocationsContext.Provider
			value={{
				locations,
				loading
			}}
		>
			{children}
		</LocationsContext.Provider>
	);
}

export function useLocations(): LocationsContextData {
	const data = useContext<LocationsContextData | undefined>(LocationsContext);

	assert(
		data,
		"Locations unavailable. Are you sure you're using this in a nested component of an LocationsProvider?"
	);

	return data;
}
