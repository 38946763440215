import { useState } from 'react';
import { useInterval } from '../../core/hooks';

function formatSecondsRemaining(milliseconds: number): string {
	const remainingSec = Math.round(milliseconds / 1000);
	const seconds = parseInt((remainingSec % 60).toString(), 10);
	const minutes = parseInt(((remainingSec / 60) % 60).toString(), 10);
	const hours = parseInt((remainingSec / 3600).toString(), 10);
	const s = seconds < 10 ? `0${seconds}` : seconds;
	const m = minutes < 10 ? `0${minutes}` : minutes;
	const h = hours < 10 ? `0${hours}` : hours;

	if (h === '00') {
		return `${m}:${s}`;
	} else {
		return `${h}:${m}:${s}`;
	}
}

type CountdownTimerProps = {
	timeoutAt: Date;
	onComplete?: () => void;
};

export function useCountdownTimer(props: CountdownTimerProps): string {
	const { timeoutAt, onComplete } = props;

	const [remainingMilliseconds, setRemainingMilliseconds] = useState<number>(
		() => {
			const now = Date.now();
			const expiresAtMilliseconds = timeoutAt.getTime();
			return expiresAtMilliseconds - now > 0 ? expiresAtMilliseconds - now : 0;
		}
	);
	useInterval(
		(id) => {
			let expiresAtMilliseconds = timeoutAt.getTime() - Date.now();
			if (expiresAtMilliseconds < 0) {
				expiresAtMilliseconds = 0;
				clearTimeout(id);
				onComplete?.();
			}
			setRemainingMilliseconds(expiresAtMilliseconds);
		},
		1000,
		[timeoutAt]
	);

	return formatSecondsRemaining(remainingMilliseconds);
}
